<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img
          v-if="!!accountDataLocale.avatar"
          :src="accountDataLocale.avatar"
        >
        </v-img>
        <v-icon
          v-else
          size="120"
        >
          mdi-account
        </v-icon>
      </v-avatar>
      <v-col
        md="6"
        cols="12"
        class="mt-6 ml-4"
      >
        <v-row><h3>昵称：{{ accountDataLocale.nickname }}</h3></v-row>
        <v-row>
          <h3 class="mt-3">
            角色：{{ role }}
          </h3>
        </v-row>
      </v-col>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.username"
              label="账号"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.userId"
              label="用户ID"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.email"
              label="邮箱"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.createdAt"
              dense
              label="创建日期"
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.mobile"
              dense
              label="手机号码"
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.company"
              dense
              outlined
              label="公司"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
            >
              确认
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    console.log(props.accountData)
    const accountDataLocale = ref(props.accountData)
    const { role } = props.accountData

    return {
      accountDataLocale,
      role,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
