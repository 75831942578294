<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="onSubmit"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="当前密码"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="新密码"
                outlined
                dense
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
<!--              <v-text-field-->
<!--                v-model="cPassword"-->
<!--                :type="isCPasswordVisible ? 'text' : 'password'"-->
<!--                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"-->
<!--                label="确认新密码"-->
<!--                outlined-->
<!--                dense-->
<!--                class="mt-3"-->
<!--                @click:append="isCPasswordVisible = !isCPasswordVisible"-->
<!--              ></v-text-field>-->
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            type="submit"
          >
            保存
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            type="reset"
            @click="resetForm"
          >
            取消
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { passwordValidator } from '@core/utils/validation'
import axios from '@axios'
import {
  modalEnquire, modalFail, toastInfo, toastSuccess,
} from '@core/utils/prompt'
import config from '../../../../config'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')
    const userData = JSON.parse(localStorage.getItem('userData'))

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const onSubmit = () => {
      if (valid.value) {
        modalEnquire('点击确认后将会保存相关用户密码').then(result => {
          if (result.isConfirmed) {
            axios.post(`${config.serviceUrl}/system/users/update/password`, {
              password: currentPassword.value,
              newPassword: newPassword.value,
              id: userData.id,
            }).then(response => {
              console.log(response)
              if (response.data.status !== 200) {
                toastInfo(response.data.message)

                return
              }
              if (response.data.data) {
                try {
                  const message = JSON.parse(response.data.data)
                  if (message.status !== 200) {
                    toastInfo(message.message)

                    return
                  }
                } catch (e) {
                  console.log(e)
                }
              }
              toastSuccess('修改成功')
            }).catch(error => {
              modalFail(error.response.data.message)
            })
          }
        })
      } else {
        validate()
      }
    }

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      validator: { passwordValidator },
      form,
      valid,
      validate,
      resetForm,
      onSubmit,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
